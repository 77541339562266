import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { IEventStoreState } from 'common/redux/event/reducer';
import {
  CLEAR_CATEGORY_EVENTS,
  CLEAR_EVENTS,
  CLEAR_EVENTS_STATE,
  CLEAR_FEATURED_EVENTS,
  GET_CATEGORY_EVENTS_FAILED,
  GET_CATEGORY_EVENTS_SUCCESS,
  GET_EVENTS_FAILED,
  GET_EVENTS_SUCCESS,
  GET_FEATURED_EVENTS_FAILED,
  GET_FEATURED_EVENTS_SUCCESS,
} from 'common/redux/events/constants';
import { updateObject } from 'common/utils/shared';

export interface IEventListState {
  events: IEventStoreState[];
  totalEvents: number;
  totalPages: number;
}

export interface IEventsStoreState {
  featuredEvents: IEventListState;
  categoryEvents: IEventListState;
  allEvents: IEventListState;
}

const initialState: IEventsStoreState = {
  featuredEvents: {
    events: [],
    totalEvents: 0,
    totalPages: 0,
  },
  categoryEvents: {
    events: [],
    totalEvents: 0,
    totalPages: 0,
  },
  allEvents: {
    events: [],
    totalEvents: 0,
    totalPages: 0,
  },
};

const eventsReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.events);

    case GET_EVENTS_SUCCESS:
    case GET_EVENTS_FAILED:
      return updateObject(state, {
        allEvents: payload,
      });

    case CLEAR_EVENTS:
      return updateObject(state, {
        allEvents: initialState.allEvents,
      });

    case GET_FEATURED_EVENTS_SUCCESS:
    case GET_FEATURED_EVENTS_FAILED:
      return updateObject(state, {
        featuredEvents: payload,
      });

    case CLEAR_FEATURED_EVENTS:
      return updateObject(state, {
        featuredEvents: initialState.featuredEvents,
      });

    case GET_CATEGORY_EVENTS_SUCCESS:
    case GET_CATEGORY_EVENTS_FAILED:
      return updateObject(state, {
        categoryEvents: payload,
      });

    case CLEAR_CATEGORY_EVENTS:
      return updateObject(state, {
        categoryEvents: initialState.featuredEvents,
      });

    case CLEAR_EVENTS_STATE:
      return initialState;

    default:
      return state;
  }
};

export default eventsReducer;
