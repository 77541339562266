import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import {
  IAcfComponentsProps,
  TAcfComponent,
} from '@jpp/organisms/AcfComponents/types';
import { TReduxError } from 'common/typings/types';

import { updateObject } from '../../utils/shared';
import * as actions from './constants';

interface IPostAcfProps {
  subtitle?: string;
  components?: TAcfComponent[];
}

export interface IPostStoreState {
  id: number;
  code?: string;
  post_title?: string;
  post_name?: string;
  title: string;
  slug: string;
  date: string;
  date_modified: string;
  excerpt: string;
  status: string;
  content: string;
  author: string;
  media?: Core.IMediaSources;
  featured_image?: Core.IMedia;
  yoast: Core.IYoastMeta;
  categories: Core.ITaxonomyTerm[];
  category_names: string[];
  tags: Core.ITaxonomyTerm[];
  acf: IPostAcfProps;
  acf_fields: IPostAcfProps;
  error?: TReduxError;
}

const initialState = {
  yoast: {} as Core.IYoastMeta,
  acf: {} as IAcfComponentsProps,
  categories: [] as Core.ITaxonomyTerm[],
  tags: [] as Core.ITaxonomyTerm[],
} as any as IPostStoreState;

export const postReducer = (
  state = initialState,
  action: AnyAction
): IPostStoreState => {
  switch (action.type) {
    case HYDRATE:
      return updateObject(state, action.payload.post);

    case actions.GET_POST_SUCCESS:
      return updateObject(state, action.payload);

    case actions.GET_POST_FAILED:
      return action.payload;

    case actions.CLEAR_POST:
      return initialState;

    default:
      return state;
  }
};
