import classNames from 'classnames';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import Head from 'next/head';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink';
import Button from '@jpp/molecules/Button/Button';
import { ECtaTheme, ESize } from 'common/typings/enums';
import { GHBC_COOKIE_POLICY } from 'common/utils/constants/cookies';
import { isClient, VARIANT_EASE } from 'common/utils/shared';
import { daysToSeconds, getExpiryDate } from 'common/utils/shared/time';

import './CookieBanner.scss';

interface ICookieBannerProps {
  className?: string;
  useTermlyBanner?: boolean;
}

const ROOT_CLASSNAME = 'CookieBanner';

const cookieBannerVariants = {
  initial: {
    opacity: 0,
    top: 'auto',
    right: 0,
    bottom: '-100%',
  },
  enter: {
    opacity: 1,
    bottom: 0,
    transition: {
      duration: 0.3,
      ease: VARIANT_EASE,
    },
  },
  exit: {
    opacity: 0,
    bottom: '-100%',
    transition: {
      duration: 0.3,
      ease: VARIANT_EASE,
    },
  },
};

const LocalCookieBanner: React.FunctionComponent<ICookieBannerProps> = ({
  className,
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const cookie = !!(isClient() && Cookies.get(GHBC_COOKIE_POLICY));

  const handleOnClose = () => {
    setIsVisible(false);
    Cookies.set(GHBC_COOKIE_POLICY, 'true', {
      expires: getExpiryDate(daysToSeconds(7)),
      path: '/',
    });
  };

  React.useEffect(() => {
    if (isClient() && !cookie) {
      setIsVisible(true);
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={cookieBannerVariants}
    >
      <div className={classNames(ROOT_CLASSNAME, className)}>
        <p className={`${ROOT_CLASSNAME}__content`}>
          We use cookies on our websites. You are free to manage this via your
          browser setting at any time. To learn more about how we use the
          cookies, please see our{' '}
          <AnchorLink
            className={`${ROOT_CLASSNAME}__link`}
            href="/privacy-policy"
            children="Privacy Policy"
          />
          .
        </p>

        <div className={`${ROOT_CLASSNAME}__button-wrapper`}>
          <Button
            link="/privacy-policy"
            className={`${ROOT_CLASSNAME}__button ${ROOT_CLASSNAME}__button--privacy-policy`}
            size={ESize.Sm}
            theme={ECtaTheme.BrandOutline}
            children="Privacy Policy"
          />

          <Button
            className={`${ROOT_CLASSNAME}__button ${ROOT_CLASSNAME}__button--accept`}
            behaviour="action"
            size={ESize.Sm}
            onClick={handleOnClose}
            children="Accept"
          />
        </div>
      </div>
    </motion.div>
  );
};

const CookieBanner: React.FunctionComponent<ICookieBannerProps> = ({
  useTermlyBanner = false,
}) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!useTermlyBanner) {
    return <LocalCookieBanner />;
  }

  return isMounted ? (
    <>
      <Head>
        <script
          key="termly-banner"
          async={true}
          id="b4d51ee9-b1c6-446a-982e-e53886b29b77"
          data-name="termly-embed-banner"
          src="https://app.termly.io/embed.min.js"
        />
      </Head>
    </>
  ) : null;
};

export default CookieBanner;
