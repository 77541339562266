import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { updateObject } from 'common/utils/shared';

import {
  CLEAR_PRODUCT,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_SUCCESS,
} from '../constants';

const initialState = {} as Core.IProduct;

export const productReducer = (
  state = initialState,
  action: AnyAction
): any => {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.wooCommerce.product);

    case GET_PRODUCT_SUCCESS:
    case GET_PRODUCT_FAILED:
      return payload;

    case CLEAR_PRODUCT:
      return initialState;

    default:
      return state;
  }
};
