import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';
import { Stripe } from 'stripe';

import { IBillingFormValues } from '@jpp/organisms/CheckoutForm/components/BillingFormFields/BillingFormFields';
import { IShippingFormValues } from '@jpp/organisms/CheckoutForm/components/DeliveryFormFields/DeliveryFormFields';
import { TReduxError } from 'common/typings/types';
import { updateObject } from 'common/utils/shared';

import {
  CLEAR_AUTH,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from './constants';

export enum EAuthRole {
  Admin = 'administrator',
  Customer = 'customer',
  Editor = 'editor',
}

export interface IAuthStoreState {
  isLoggedIn: boolean;
  id: number;
  avatar: string;
  token: string;
  first_name: string;
  last_name: string;
  display_name: string;
  nicename: string;
  email: string;
  hasSubscriptions: boolean;
  phone: string;
  roles: EAuthRole[];
  marketing_emails: boolean;
  billing: IBillingFormValues;
  shipping: IShippingFormValues;
  meta_data: Record<string, string>;
  meta: Record<string, string>;
  user: Record<string, string>;
  stripe_data?: {
    id: Stripe.Customer['id'];
    paymentMethods: Stripe.PaymentMethod[];
  };
  error?: TReduxError;
}

export type TAuthStoreState = IAuthStoreState;

const initialState: Pick<IAuthStoreState, 'id' | 'isLoggedIn'> = {
  id: 0,
  isLoggedIn: false,
};

export const authReducer = (
  state = initialState as IAuthStoreState,
  action: AnyAction
): TAuthStoreState => {
  switch (action.type) {
    case HYDRATE:
      return updateObject(state, action.payload.auth);
    case LOGIN_SUCCESS:
    case GET_USER_SUCCESS:
      return updateObject(state, { isLoggedIn: true, ...action.payload });
    case LOGIN_FAILED:
    case GET_USER_FAILED:
      return updateObject(state, { isLoggedIn: false, error: action.payload });

    case LOGOUT_SUCCESS:
    case CLEAR_AUTH:
      return initialState as IAuthStoreState;

    default:
      return state;
  }
};
