import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { updateObject } from 'common/utils/shared';

import {
  CLEAR_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_FAILED,
  GET_PRODUCT_CATEGORY_SUCCESS,
} from '../constants';

const initialState = {} as Core.IProductCategory;

export const productCategoryReducer = (
  state = initialState,
  action: AnyAction
): any => {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.wooCommerce.category);

    case GET_PRODUCT_CATEGORY_SUCCESS:
    case GET_PRODUCT_CATEGORY_FAILED:
      return payload;

    case CLEAR_PRODUCT_CATEGORY:
      return initialState;

    default:
      return state;
  }
};
