import { Context, createWrapper, MakeStore } from 'next-redux-wrapper';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { IPaymentStoreState } from 'common/redux/payment/types';
import { TFuncValueVoid, TFuncVoid } from 'common/typings/types';

import { TAuthStoreState } from './auth/reducer';
import {
  ICategoryStoreState,
  TCategoriesStoreState,
} from './categories/reducer';
import { ICoreStoreState } from './core/reducer';
import { IEventsStoreState } from './events/reducer';
import { IPageStoreState } from './page/reducer';
import { IPostStoreState } from './post/reducer';
import { IPostsStoreState, TPostsStoreState } from './posts/reducer';
import rootReducer from './rootReducer';
import { ITagStoreState, TTagsStoreState } from './tags/reducer';
import { IWooCommerceStoreState } from './wooCommerce/reducer';

export interface IReduxState {
  auth: TAuthStoreState;
  core: ICoreStoreState;
  page: IPageStoreState;
  posts: IPostsStoreState;
  payment: IPaymentStoreState;
  events: IEventsStoreState;
  wooCommerce: IWooCommerceStoreState;
  post: IPostStoreState;
  tag: ITagStoreState;
  tags: TTagsStoreState;
  category: ICategoryStoreState;
  categories: TCategoriesStoreState;
}

export interface IReduxDispatch {
  onSetMenuState: TFuncValueVoid<boolean>;
  onSetAppLoading: TFuncValueVoid<boolean>;
  onSetAppError: TFuncValueVoid<boolean>;
  onGetSiteMeta: () => ICoreStoreState;
  onGetPage: (slug: string) => IPageStoreState;
  onGetPost: (slug: string) => IPostStoreState;
  onGetPosts: () => TPostsStoreState;
  onGetCategoryPosts: () => TPostsStoreState;
  onGetTagPosts: () => TPostsStoreState;
  onGetCategories: () => TCategoriesStoreState;
  onClearPage: TFuncVoid;
  onClearCategory: TFuncVoid;
  onClearCategories: TFuncVoid;
  onClearTag: TFuncVoid;
  onClearTags: TFuncVoid;
  onClearPost: TFuncVoid;
  onClearPosts: TFuncVoid;
}

export type TReduxProps = IReduxState & IReduxDispatch;

const makeStore: MakeStore<IReduxState> = (
  context: Context
): Store<IReduxState> => {
  return createStore(
    rootReducer,
    {} as IReduxState,
    composeWithDevTools(applyMiddleware(thunk))
  );
};

export const storeWrapper = createWrapper(makeStore, { debug: false });

export default makeStore;
