import { combineReducers } from 'redux';

import { authReducer } from './auth/reducer';
import { categoriesReducer } from './categories/reducer';
import { categoryReducer } from './category/reducer';
import { coreReducer } from './core/reducer';
import eventsReducer from './events/reducer';
import { pageReducer } from './page/reducer';
import { paymentReducer } from './payment/reducer';
import { postReducer } from './post/reducer';
import { postsReducer } from './posts/reducer';
import { tagReducer } from './tag/reducer';
import { tagsReducer } from './tags/reducer';
import wooCommerceRootReducer from './wooCommerce/reducer';

/**
 * Root Reducer
 */
export const rootReducer = combineReducers({
  auth: authReducer,
  page: pageReducer,
  core: coreReducer,
  events: eventsReducer,
  post: postReducer,
  posts: postsReducer,
  payment: paymentReducer,
  wooCommerce: wooCommerceRootReducer,
  tag: tagReducer,
  tags: tagsReducer,
  category: categoryReducer,
  categories: categoriesReducer,
});

export default rootReducer;
