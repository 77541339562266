import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { updateObject } from 'common/utils/shared';

import {
  CLEAR_PAYMENT_GATEWAYS,
  GET_PAYMENT_GATEWAYS_FAILED,
  GET_PAYMENT_GATEWAYS_SUCCESS,
} from '../constants';

const initialState = [] as Core.IPaymentGateway[];

export const paymentGatewayReducer = (
  state = initialState,
  action: AnyAction
): any => {
  const { type, paymentGateway, payload } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.wooCommerce.paymentGateways);

    case GET_PAYMENT_GATEWAYS_SUCCESS(paymentGateway):
    case GET_PAYMENT_GATEWAYS_FAILED(paymentGateway):
      return payload;

    case CLEAR_PAYMENT_GATEWAYS(paymentGateway):
      return initialState;

    default:
      return state;
  }
};
