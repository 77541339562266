import { combineReducers, Reducer } from 'redux';

import {
  IOrdersStoreState,
  ordersReducer,
} from 'common/redux/wooCommerce/orders/reducer';

import { cartReducer, ICartStoreState } from './cart/reducer';
import { productCategoriesReducer } from './categories/reducer';
import { productCategoryReducer } from './category/reducer';
import { paymentGatewayReducer } from './paymentGateway/reducer';
import { productReducer } from './product/reducer';
import { IProductsStoreState, productsReducer } from './products/reducer';

export interface IWooCommerceStoreState {
  product: Core.IProduct;
  products: IProductsStoreState;
  paymentGateways: Core.IPaymentGateway[];
  categories: Core.IProductCategory[];
  category: Core.IProductCategory;
  cart: ICartStoreState;
  orders: IOrdersStoreState;
}

export const wooCommerceRootReducer: Reducer<IWooCommerceStoreState> =
  combineReducers<IWooCommerceStoreState>({
    product: productReducer,
    products: productsReducer,
    paymentGateways: paymentGatewayReducer,
    categories: productCategoriesReducer,
    category: productCategoryReducer,
    cart: cartReducer,
    orders: ordersReducer,
  });

export default wooCommerceRootReducer;
