import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { ETaxonomy } from 'common/typings/enums';
import { TReduxError } from 'common/typings/types';
import { updateObject } from 'common/utils/shared';

import * as actions from './constants';

export interface ICategoryStoreState {
  id: number;
  code?: string;
  count: number;
  description: string;
  name: string;
  slug: string;
  taxonomy: ETaxonomy;
  yoast: Core.IYoastMeta;
  error?: TReduxError;
}

export type TCategoriesStoreState = ICategoryStoreState[] | TReduxError;

const initialState = [] as ICategoryStoreState[];

export const categoriesReducer = (
  state = initialState,
  action: AnyAction
): TCategoriesStoreState => {
  switch (action.type) {
    case HYDRATE:
      return updateObject(state, action.payload.categories);

    case actions.GET_CATEGORIES_SUCCESS:
    case actions.GET_CATEGORIES_FAILED:
      return action.payload;

    case actions.CLEAR_CATEGORIES:
      return initialState;

    default:
      return state;
  }
};
