import Head from 'next/head';
import React from 'react';

import { IGTMConfig } from '../../types';

export const GtmScript: React.FunctionComponent<IGTMConfig> = ({
  isEnabled,
  gtmId,
  queryConfig,
}) => {
  if (!isEnabled) {
    return null;
  }

  const gtmBase = 'https://www.googletagmanager.com/gtm.js';
  const query = queryConfig ? `+'${queryConfig}'` : '';
  const gtmScript =
    'window.dataLayer = window.dataLayer || [];' +
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});" +
    "var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;" +
    `j.src='${gtmBase}?id='+i+dl${query};f.parentNode.insertBefore(j,f);})` +
    `(window,document,'script','dataLayer','${gtmId}');`;

  return (
    <Head>
      <script
        key="gtmscript"
        id="gtmscript"
        className="gtmScript"
        dangerouslySetInnerHTML={{ __html: gtmScript }}
      />
    </Head>
  );
};
