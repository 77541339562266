import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { updateObject } from 'common/utils/shared';

import { CLEAR_PAYMENT_INTENT, SET_PAYMENT_INTENT } from './constants';
import { IPaymentStoreState } from './types';

const initialState: IPaymentStoreState = {
  paymentIntent: null,
};

export const paymentReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.payment);

    case SET_PAYMENT_INTENT:
      return updateObject(state, { paymentIntent: payload });

    case CLEAR_PAYMENT_INTENT:
      return updateObject(state, { paymentIntent: undefined });

    default:
      return state;
  }
};
