import { HYDRATE } from 'next-redux-wrapper';
import { ReactText } from 'react';
import { AnyAction } from 'redux';

import { EProductType } from 'common/typings/enums/product';
import { updateObject } from 'common/utils/shared';

import {
  ADD_TO_CART_FAILED,
  ADD_TO_CART_SUCCESS,
  CLEAR_CART,
  CLEAR_CART_SUCCESS,
  GET_CART_CUSTOMER_SUCCESS,
  GET_CART_FAILED,
  GET_CART_SHIPPING_FAILED,
  GET_CART_SHIPPING_PENDING,
  GET_CART_SHIPPING_SUCCESS,
  GET_CART_SUCCESS,
  IS_ADDED_TO_BAG,
  IS_ADDING_TO_BAG,
  IS_CART_LOADED,
  RESET_ADD_TO_CART_FAILED,
  RESET_CART_STORE,
  SET_CART_SHIPPING_SUCCESS,
} from './constants';

interface ICartCustomerUser {
  ID: number;
  first_name: string;
  last_name: string;
}

interface ICartCustomerBilling {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  phone: string;
  country: string;
  state: string;
  postcode: string;
  city: string;
  address: string;
  address_1: string;
  address_2: string;
}

interface ICartCustomerShipping {
  first_name: string;
  last_name: string;
  company: string;
  country: string;
  state: string;
  postcode: string;
  city: string;
  address: string;
  address_1: string;
  address_2: string;
}

export interface ICartCustomer {
  user: ICartCustomerUser;
  billing: ICartCustomerBilling;
  shipping: ICartCustomerShipping;
  has_calculated_shipping: boolean;
  is_vat_exempt: 'no' | 'yes';
}

export interface ICartTotals {
  subtotal: string;
  subtotal_tax: string;
  shipping_total: string;
  shipping_tax: string;
  shipping_taxes: Record<ReactText, ReactText>;
  discount_total: string;
  discount_tax: string;
  cart_contents_total: string;
  cart_contents_tax: string;
  cart_contents_taxes: Record<ReactText, ReactText>;
  fee_total: string;
  fee_tax: number;
  fee_taxes: any[];
  total: string;
  total_tax: string;
  error?: any;
}

export interface ICartCoupon {
  coupon: string;
  label: string;
  saving: string;
  saving_html: string;
}

interface ICartLineItemStockState {
  status: string;
  stock_quantity: number;
  hex_color: string;
}

// "permalink": "http://shop.gipsyhillbrew.test/product/spring-mixed-pack/",

export interface ICartLineItem {
  key: string;
  product_id: number;
  billing_interval?: string;
  billing_period?: string;
  variation_id: number;
  variation: any[];
  quantity: number;
  bundled_by?: string;
  isPrimaryBundleProduct: boolean;
  isBundleItem: boolean;
  product_type?: EProductType;
  virtual?: boolean;
  downloadable?: boolean;
  data_hash: string;
  line_tax_data: any;
  line_subtotal: number;
  line_subtotal_tax: number;
  line_total: number;
  line_tax: number;
  data: any;
  product_image: string;
  product_name: string;
  product_title: string;
  product_price: string;
  product_link: string;
  slug: string;
  categories: any;
  tags: any;
  sku: string;
  weight: string;
  stock_status: ICartLineItemStockState;
  max_purchase_quantity: number;
  subscription_data?: ICartLineItemSubscriptionData;
}

export interface ICartLineItemSubscriptionData {
  billing_period: ISubscriptionBillingPeriod;
  sign_up_fee: string;
  pay_now: string;
  needs_one_time_shipping: boolean;
  price_string: string;
  first_renewal: ISubscriptionFirstRenewal;
}

export interface ISubscriptionBillingPeriod {
  interval: string;
  period: string;
  length: number;
  trial_period: string;
  trial_length: number;
  trial_expiration_date: number;
  expiration_date: number;
}

export interface ISubscriptionFirstRenewal {
  date: string;
  time: number;
  string: string;
}

export interface ICartShippingMethod {
  key: string;
  method_id: string;
  instance_id: number;
  label: string;
  cost: string;
  html: string;
  taxes: Record<string, number>;
  chosen_method: boolean;
}

export interface ICartStoreState {
  cartKey: string;
  customer: ICartCustomer;
  items: ICartLineItem[];
  totalItems: number;
  needsShipping: boolean;
  needsPayment: boolean;
  cartTotals: ICartTotals;
  shippingMethods: ICartShippingMethod[];
  isAddingToBag: boolean;
  isAddedToBag: boolean;
  isCartLoaded: boolean;
  addToCartFailed: boolean;
  getCartShippingFailed: boolean;
  coupons?: Record<string | number, ICartCoupon> | ICartCoupon[];
  error?: any;
}

const initialCartState: ICartStoreState = {
  cartKey: '',
  customer: {} as ICartCustomer,
  items: [],
  totalItems: 0,
  needsShipping: true,
  needsPayment: true,
  cartTotals: {} as ICartTotals,
  shippingMethods: [],
  isAddingToBag: false,
  isAddedToBag: false,
  isCartLoaded: false,
  addToCartFailed: false,
  getCartShippingFailed: false,
  coupons: [] as any,
};

export const cartReducer = (
  state = initialCartState,
  action: AnyAction
): any => {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.wooCommerce.cart);

    case GET_CART_CUSTOMER_SUCCESS:
      return updateObject(state, { customer: payload });

    case GET_CART_SHIPPING_SUCCESS:
      const shippingMethods: ICartShippingMethod[] = [];

      Object.keys(payload).forEach((key) => {
        return shippingMethods.push(payload[key]);
      });

      return updateObject(state, {
        shippingMethods,
        getCartShippingFailed: false,
      });

    case IS_ADDING_TO_BAG:
      return updateObject(state, { isAddingToBag: payload });

    case IS_ADDED_TO_BAG:
      return updateObject(state, { isAddedToBag: payload });

    case IS_CART_LOADED:
      return updateObject(state, { isCartLoaded: payload });

    case GET_CART_SUCCESS:
      const { error, ...restOfState } = state;
      return updateObject(restOfState, payload);

    case RESET_ADD_TO_CART_FAILED:
    case ADD_TO_CART_SUCCESS:
      return updateObject(state, { addToCartFailed: false });

    case ADD_TO_CART_FAILED:
      return updateObject(state, { addToCartFailed: true });

    case SET_CART_SHIPPING_SUCCESS:
      return updateObject(state, { needsShipping: false });

    case GET_CART_FAILED:
      return updateObject(state, { error: payload });

    case GET_CART_SHIPPING_PENDING:
      return updateObject(state, {
        getCartShippingFailed: false,
        shippingMethods: [],
      });

    case GET_CART_SHIPPING_FAILED:
      return updateObject(state, {
        getCartShippingFailed: true,
        shippingMethods: [],
      });

    case CLEAR_CART:
    case CLEAR_CART_SUCCESS:
    case RESET_CART_STORE:
      return initialCartState;

    default:
      return state;
  }
};
