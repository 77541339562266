import React from 'react';

import { IGTMConfig } from '../../types';

export const GtmNoScript: React.FunctionComponent<IGTMConfig> = ({
  isEnabled,
  gtmId,
  queryConfig,
}) => {
  if (!isEnabled) {
    return null;
  }

  const noScriptUrl = 'https://www.googletagmanager.com/ns.html';
  const query = queryConfig ? `+'${queryConfig}'` : '';
  const noScriptQuery = `?id=${gtmId}${query}`;
  const noScriptSrc = noScriptUrl + noScriptQuery;

  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe src='${noScriptSrc}' width='0' height='0' style='display: none; opacity: 0'></iframe>`,
      }}
    />
  );
};
