import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { updateObject } from 'common/utils/shared';

import { IWooCommerceAPIOrder } from '../../../utils/transformers/order.transformer';
import {
  SUBMIT_ORDER_PENDING_FAILED,
  SUBMIT_ORDER_PENDING_SUCCESS,
} from './contants';

export type TOrder = Omit<
  IWooCommerceAPIOrder,
  | '_links'
  | 'customer_ip_address'
  | 'customer_note'
  | 'customer_user_agent'
  | 'date_completed_gmt'
  | 'date_created_gmt'
  | 'date_modified_gmt'
  | 'date_paid_gmt'
  | 'meta_data'
>;

export interface IOrdersStoreState {
  orders?: TOrder[];
  currentOrder?: TOrder;
  error?: any;
}

export interface IOrderCouponLine {
  code?: string;
  discount?: string;
}

const initialOrdersState: IOrdersStoreState = {
  orders: [],
  currentOrder: {} as TOrder,
};

export interface ISubscriptionOrder {
  customer_id: number;
  status: string;
  billing_period: string;
  billing_interval: number;
  start_date: string;
  next_payment_date: string;
  payment_method: 'stripe';
  payment_details: {
    post_meta: {
      _stripe_customer_id: string;
      _stripe_source_id: string;
    };
  };
  billing: {
    first_name: string;
    last_name: string;
    address_1: string;
    address_2?: string;
    city: string;
    state?: string;
    postcode: string;
    country: string;
    email: string;
    phone?: string;
  };
  shipping: {
    first_name: string;
    last_name: string;
    address_1: string;
    address_2?: string;
    city: string;
    state?: string;
    postcode: string;
    country: string;
    email: string;
    phone?: string;
  };
  line_items: {
    product_id: number;
    quantity: number;
  }[];
}

export const ordersReducer = (
  state = initialOrdersState,
  action: AnyAction
): any => {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.wooCommerce.orders);

    case SUBMIT_ORDER_PENDING_SUCCESS:
      const { error, ...rest } = state;

      return updateObject<IOrdersStoreState>(rest, { currentOrder: payload });

    case SUBMIT_ORDER_PENDING_FAILED:
      return updateObject<IOrdersStoreState>(state, { error: payload });

    default:
      return state;
  }
};
