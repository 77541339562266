import React from 'react';

import { IGTMConfig } from 'common/thirdParty/GoogleTagManager/types';

import ConfigProvider from '../../services/configProvider';
import { GtmNoScript } from './components/GtmNoScript/GtmNoScript';
import { GtmScript } from './components/GtmScript/GtmScript';
import { GTM_ID, GTM_STAGING_ID } from './constants';

const RUN_ENV = ConfigProvider.getValue('RUN_ENV');
const gtmId = RUN_ENV === 'production' ? GTM_ID : GTM_STAGING_ID;

const GTMConfig = (): IGTMConfig => {
  return {
    gtmId,
    isEnabled: !!gtmId,
  };
};

export const GoogleTagManager: React.FunctionComponent = () => {
  const gtm = GTMConfig();

  if (!gtm || !gtm.gtmId) {
    return null;
  }

  return (
    <>
      <GtmScript {...gtm} />
      <GtmNoScript {...gtm} />
    </>
  );
};
