import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { IAcfComponentsProps } from '@jpp/organisms/AcfComponents/types';
import { EPageType, EStatus } from 'common/typings/enums';
import { TReduxError } from 'common/typings/types';

import { updateObject } from '../../utils/shared';
import { CLEAR_PAGE, GET_PAGE_FAILED, GET_PAGE_SUCCESS } from './constants';

export interface IPageObjectState {
  ID: number;
  id?: number;
  post_title: string;
  post_name: string;
  featured_image?: Core.IMedia;
  post_type: string | EPageType;
  post_status: EStatus;
  acf?: IAcfComponentsProps;
  acf_fields?: IAcfComponentsProps;
}

export interface IPageStoreState {
  id: number;
  code?: string;
  post_title?: string;
  title: string;
  description?: string;
  slug: string;
  date: string;
  terms?: Core.ITaxonomyTerm[];
  date_modified?: string;
  featured_image?: Core.IMedia;
  media?: Core.IMediaSizes;
  excerpt: string;
  status: EStatus;
  content: string;
  author: string;
  yoast: Core.IYoastMeta;
  acf: IAcfComponentsProps;
  acf_fields?: IAcfComponentsProps;
  error?: TReduxError;
}

const initialState = {} as IPageStoreState;

export const pageReducer = (
  state = initialState,
  action: AnyAction
): IPageStoreState => {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, payload.page);
    case GET_PAGE_SUCCESS:
      return updateObject(state, {
        ...payload,
        error: false,
      });

    case GET_PAGE_FAILED:
      return payload;

    case CLEAR_PAGE:
      return initialState;

    default:
      return state;
  }
};
