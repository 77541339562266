import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { TReduxError } from 'common/typings/types';
import { updateObject } from 'common/utils/shared';

import { APP_TITLE, SITE_DESCRIPTION } from '../../utils/constants';
import {
  GET_MENU_FAILED,
  GET_MENU_SUCCESS,
  GET_SITE_META_FAILED,
  GET_SITE_META_SUCCESS,
  SET_APP_ERROR_FALSE,
  SET_APP_ERROR_TRUE,
  SET_APP_LOADING_FALSE,
  SET_APP_LOADING_TRUE,
  SET_INITIAL_FETCH,
  SET_MENU_STATE_CLOSE,
  SET_MENU_STATE_OPEN,
} from './constants';

export interface ICoreStoreState {
  title: string;
  description: string;
  hasError: boolean;
  isLoading: boolean;
  isMenuOpen: boolean;
  options: Core.IOptions;
  menus: Core.ICoreMenus;
  initialFetch: boolean;
  error?: TReduxError;
}

const initialState: ICoreStoreState = {
  title: APP_TITLE,
  description: SITE_DESCRIPTION,
  hasError: false,
  isLoading: false,
  isMenuOpen: false,
  options: {} as Core.IOptions,
  menus: {
    primaryMenu: [],
    footerMenuOne: [],
    footerMenuTwo: [],
    termsMenu: [],
  },
  initialFetch: false,
};

export const coreReducer = (
  state: ICoreStoreState = initialState,
  action: AnyAction
): ICoreStoreState => {
  switch (action.type) {
    case HYDRATE:
      return updateObject(state, action.payload.core);

    case GET_MENU_SUCCESS(action?.menuType):
    case GET_MENU_FAILED(action?.menuType):
      return updateObject(state, {
        menus: {
          ...state.menus,
          [action?.menuType]: action?.payload,
        },
      });

    case GET_SITE_META_SUCCESS:
    case GET_SITE_META_FAILED:
      return updateObject(state, action.payload);

    case SET_INITIAL_FETCH:
      return updateObject(state, { initialFetch: action.payload });

    case SET_APP_ERROR_TRUE:
    case SET_APP_ERROR_FALSE:
      return updateObject(state, { hasError: action.payload });

    case SET_APP_LOADING_TRUE:
    case SET_APP_LOADING_FALSE:
      return updateObject(state, { isLoading: action.payload });

    case SET_MENU_STATE_OPEN:
    case SET_MENU_STATE_CLOSE:
      return updateObject(state, { isMenuOpen: action.payload });

    default:
      return state;
  }
};
