import { AnyAction, Dispatch, Store } from 'redux';

import { EMenuType, EPaymentGateway } from 'common/typings/enums';

import {
  getCategoryEvents,
  getCurrentUser,
  getEvents,
  getMenu,
  getPaymentGateways,
  getPosts,
  getProductCategories,
  getProducts,
  getSiteMeta,
  setAppError,
  setInitialFetch,
} from './rootActions';

export const preloadStore = async (
  store: Store,
  authToken?: string
): Promise<void> => {
  const { dispatch } = store;

  try {
    if (authToken) {
      await auth(dispatch, authToken);
    }

    await core(dispatch);
    await dispatchMenus(dispatch);
    await dispatchWooCommerce(dispatch);
    await dispatchPaymentGateways(dispatch);
    await posts(dispatch);
    await events(dispatch);
    await dispatch(setInitialFetch(true));
  } catch (e) {
    await error(dispatch);
  }
};

const auth = async (dispatch: Dispatch, authToken: string): Promise<void> => {
  try {
    await Promise.all([
      dispatch(getCurrentUser(authToken) as any) as AnyAction,
    ]);
  } catch (e) {
    await error(dispatch);
  }
};

const core = async (dispatch: Dispatch): Promise<void> => {
  try {
    await Promise.all([dispatch(getSiteMeta() as any as AnyAction)]);
  } catch (e) {
    await error(dispatch);
  }
};

export const dispatchMenus = async (dispatch: Dispatch): Promise<void> => {
  try {
    await Promise.all([
      dispatch(getMenu(EMenuType.PrimaryMenu) as any as AnyAction),
      dispatch(getMenu(EMenuType.MobileMenu) as any as AnyAction),
      dispatch(getMenu(EMenuType.FooterMenuOne) as any as AnyAction),
      dispatch(getMenu(EMenuType.FooterMenuTwo) as any as AnyAction),
      dispatch(getMenu(EMenuType.TermsMenu) as any as AnyAction),
    ]);
  } catch (e) {
    await error(dispatch);
  }
};

export const dispatchWooCommerce = async (dispatch: Dispatch) => {
  try {
    await Promise.all([
      dispatch(getProducts() as any as AnyAction),
      dispatch(getProductCategories() as any as AnyAction),
    ]);
  } catch (e) {
    await error(dispatch);
  }
};

export const dispatchPaymentGateways = async (dispatch: Dispatch) => {
  try {
    await Promise.all([
      dispatch(getPaymentGateways(EPaymentGateway.All, {}) as any as AnyAction),
    ]);
  } catch (e) {
    await error(dispatch);
  }
};

const posts = async (dispatch: Dispatch) => {
  try {
    await Promise.all([dispatch(getPosts() as any as AnyAction)]);
  } catch (e) {
    await error(dispatch);
  }
};

const events = async (dispatch: Dispatch) => {
  try {
    await Promise.all([
      dispatch(getEvents() as any as AnyAction),
      dispatch(getCategoryEvents('featured') as any),
    ]);
  } catch (e) {
    await error(dispatch);
  }
};

const error = async (dispatch: Dispatch) => {
  await dispatch(setAppError(true));
  await dispatch(setInitialFetch(false));
};
