import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import noop from 'lodash/noop';
import App, { AppContext, AppInitialProps } from 'next/app';
import { AppContextType } from 'next/dist/next-server/lib/utils';
import Head from 'next/head';
import Router from 'next/router';
import React from 'react';
import { connect } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax/cjs';

import CookieBanner from '@jpp/organisms/CookieBanner';
import { AgeVerificationModal } from '@jpp/organisms/Modal';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { getAuth } from 'common/redux/auth/selectors';
import { IReduxState, storeWrapper } from 'common/redux/createStore';
import { preloadStore } from 'common/redux/preloadStore';
import { getCart, getCurrentUser } from 'common/redux/rootActions';
import { trackPageViews } from 'common/tracking/core';
import { Tracking } from 'common/tracking/Tracking';
import { EPageType } from 'common/typings/enums';
import { TFuncVoid } from 'common/typings/types';
import { GHBC_AUTH_TOKEN } from 'common/utils/constants/cookies';

import '../node_modules/flag-icon-css/css/flag-icon.min.css';
import '../node_modules/flexigridcss/dist/flexigridcss.min.css';
import { version } from '../package.json';
import { getCookieServerSide } from '../server/utils';
import ConfigProvider from '../src/services/configProvider';
import './_app.scss';

Sentry.init({
  enabled:
    process.env.RUN_ENV === 'production' ||
    process.env.RUN_ENV === 'development',
  environment: process.env.RUN_ENV,
  dsn: 'https://42ea4c79c4f947eb96dffdbe9224a4aa@o444054.ingest.sentry.io/5418562',
  release: `gipsyhillbrew@${version}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
});

Router.events.on('routeChangeComplete', (url: string) => {
  trackPageViews(url);
});

interface IStoreCoreApp {
  user: IAuthStoreState;
}

interface IDispatchCoreApp {
  getCart: TFuncVoid;
  getCurrentUser: TFuncVoid;
}

class CoreApp extends App<AppContextType & IStoreCoreApp & IDispatchCoreApp> {
  get Component() {
    const { Component, pageProps } = this.props;
    return <Component {...pageProps} />;
  }

  static getDerivedStateFromError(error: Error) {
    Sentry.captureException(error);
    return {
      hasError: true,
    };
  }

  static async getInitialProps({
    Component,
    ctx,
  }: Pick<AppContext, 'Component' | 'ctx'>): Promise<AppInitialProps> {
    const { store, req } = ctx;
    if (store && req) {
      const contentFetched = store.getState().initialFetch;
      const authToken = await getCookieServerSide(req, GHBC_AUTH_TOKEN);

      if (!contentFetched) {
        await preloadStore(store, authToken);
      }
    }
    return {
      pageProps: Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {},
    };
  }

  componentDidMount = () => {
    const hasAuthToken = Cookies.get(GHBC_AUTH_TOKEN);
    this.props.getCart();
    if (hasAuthToken && this.props.user.isLoggedIn && this.props.user.email) {
      this.props.getCurrentUser();
    }
  };

  render() {
    const { pathname, asPath } = this.props.router;
    const hasAgeVerification =
      ConfigProvider.getValue('AGE_VERIFICATION') === 'true';

    const key = pathname.includes(EPageType.MyAccount) ? pathname : asPath;

    return (
      <>
        <Head>
          <meta
            id="viewport"
            name="viewport"
            content="width=device-width, initial-scale=1"
          />
        </Head>

        <ParallaxProvider>
          <AnimatePresence exitBeforeEnter={true}>
            <React.Fragment key={key}>{this.Component}</React.Fragment>
          </AnimatePresence>
        </ParallaxProvider>
        <CookieBanner useTermlyBanner={true} />
        <Tracking />
        {hasAgeVerification && <AgeVerificationModal onClose={noop} />}
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStoreCoreApp => ({
  user: getAuth(state) || {},
});

const mapDispatchToProps = {
  getCart,
  getCurrentUser,
};

export default Sentry.withProfiler(
  storeWrapper.withRedux(
    connect<IStoreCoreApp, IDispatchCoreApp, AppContextType>(
      mapStateToProps,
      mapDispatchToProps
    )(CoreApp)
  )
);
