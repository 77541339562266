import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { EStatus } from 'common/typings/enums';
import {
  EProductListing,
  EProductStock,
  EProductType,
} from 'common/typings/enums/product';
import { updateObject } from 'common/utils/shared';

import {
  CLEAR_PRODUCTS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_SUCCESS,
} from '../constants';

export interface IProductsStoreState {
  [EProductListing.All]: IProductsState;
  [EProductListing.Category]: IProductsState;
  [EProductListing.Categories]: IProductsState;
  [EProductListing.Related]: IProductsState;
  [EProductListing.Shop]: IProductsState;
  [EProductListing.UpSell]: IProductsState;
}

export interface IProduct extends Core.IProduct {
  type: EProductType;
  status: EStatus;
  meta_data?: any;
  stock_status: EProductStock;
}

export interface IProductsState {
  products: IProduct[];
  totalProducts: string;
  totalPages: string;
  currentPage: string;
}

const defaultProductsState: IProductsState = {
  products: [],
  currentPage: '',
  totalPages: '',
  totalProducts: '',
};

const initialState: IProductsStoreState = {
  [EProductListing.All]: defaultProductsState,
  [EProductListing.Category]: defaultProductsState,
  [EProductListing.Categories]: defaultProductsState,
  [EProductListing.Related]: defaultProductsState,
  [EProductListing.Shop]: defaultProductsState,
  [EProductListing.UpSell]: defaultProductsState,
};

export const productsReducer = (
  state = initialState,
  action: AnyAction
): any => {
  const { listingType, type, payload } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.wooCommerce.products);

    case GET_PRODUCTS_SUCCESS(listingType):
    case GET_PRODUCTS_FAILED(listingType):
      return updateObject(state, {
        [listingType]: payload,
      });

    case CLEAR_PRODUCTS(listingType):
      return updateObject(state, {
        [listingType]: {},
      });

    default:
      return state;
  }
};
