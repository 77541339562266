import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { TReduxError } from 'common/typings/types';
import { updateObject } from 'common/utils/shared';

import { IPostStoreState } from '../post/reducer';
import { CLEAR_POSTS, GET_POSTS_FAILED, GET_POSTS_SUCCESS } from './constants';

export type TPostsStoreState = IPostStoreState[] | TReduxError;

export interface IPostsStoreState {
  posts: TPostsStoreState;
  totalPosts: number;
  totalPages: number;
}

const initialState = {
  posts: [] as TPostsStoreState,
} as IPostsStoreState;

export const postsReducer = (
  state = initialState,
  action: AnyAction
): IPostsStoreState => {
  const { payload, type } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.posts);

    case GET_POSTS_SUCCESS:
      const { posts, totalPages, totalPosts } = payload as IPostsStoreState;
      return updateObject(state, {
        posts,
        totalPages: totalPages ? parseInt(`${totalPages}`, 10) : 0,
        totalPosts: totalPosts ? parseInt(`${totalPosts}`, 10) : 0,
      });

    case GET_POSTS_FAILED:
      return updateObject(state, payload);

    case CLEAR_POSTS:
      return updateObject(state, {
        posts: [] as TPostsStoreState,
      });

    default:
      return state;
  }
};
