import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

import { updateObject } from 'common/utils/shared';

import {
  CLEAR_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_FAILED,
  GET_PRODUCT_CATEGORIES_SUCCESS,
} from '../constants';

const initialState = [] as Core.IProductCategory[];

export const productCategoriesReducer = (
  state = initialState,
  action: AnyAction
): any => {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return updateObject(state, action.payload.wooCommerce.categories);

    case GET_PRODUCT_CATEGORIES_SUCCESS:
    case GET_PRODUCT_CATEGORIES_FAILED:
      return payload;

    case CLEAR_PRODUCT_CATEGORIES:
      return initialState;

    default:
      return state;
  }
};
